import { FC, JSX, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import messages from 'src/components/LandingMozza/common/SeoText/messages';
import useStyles from 'src/components/LandingMozza/common/SeoText/styles';

type Props = {
  title: string | ReactElement;
  content: string | JSX.Element | (string | JSX.Element)[];
};

export const DynamicSeoText: FC<Props> = ({ title, content }) => {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="titleLg" component="h2" className={classes.title}>
        {title}
      </Typography>
      <div className={classes.scrollingArea}>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: content }}
          className={classes.content}
        />
      </div>
    </div>
  );
};

const LandingSeoText: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <DynamicSeoText
      title={formatMessage(messages.title)}
      content={formatMessage(messages.content)}
    />
  );
};

export default LandingSeoText;
