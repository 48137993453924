import axios from 'axios';
import server from 'config';

import { Subject } from '@/types/subject';

export const apiGetSubjects = async (): Promise<Subject[]> => {
  try {
    const { data } = await axios.get<Subject[]>(`${server}subject`, {
      withCredentials: true,
      validateStatus: (status) => status === 200,
    });

    return data;
  } catch {
    return [];
  }
};
