import { QueryFunction, useQuery } from '@tanstack/react-query';

export const useQueryImmutable = <TQueryFnData = unknown, TError = unknown>(
  queryKey: string[],
  queryFn: QueryFunction<TQueryFnData, string[]>
) =>
  useQuery<TQueryFnData, TError>(queryKey, queryFn, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
