import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(64),
  },
  title: {
    textAlign: 'center',
  },
  scrollingArea: {
    height: rem(624),
    overflowY: 'scroll',
    paddingRight: rem(24),
  },
  content: {
    columnCount: 1,
    [breakpoints.up('md')]: {
      columnCount: 2,
      columnGap: rem(40),
    },
    a: {
      color: palette.primary.main,
    },
  },
}));
